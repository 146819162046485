import React, { useContext } from "react";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DatasheetContext } from "./datasheet.context";

export default function StandardMasterSelection(props) {
  const {
    index,
    label,
    standardRanges,
    updateStandardRangesOfRow,
    isSupportive,
    valId,
    rowIndex,
    table,
  } = props;
  const datasheetContextValue = useContext(DatasheetContext);
  const {
    state: { staticReadingRows },
    dispatch,
  } = datasheetContextValue;

  const newRanges = standardRanges.filter((rangeObj) => {
    const [minRange, maxRange] = rangeObj.range.split("|").map((part) => {
      const [value, unit] = part.split("#");
      return { value: parseFloat(value), unit };
    });

    if (staticReadingRows) {
      let rangeCol =
        table?.defaultConfiguration &&
        JSON.parse(table?.defaultConfiguration)?.rangeCol;
      let colIndex = parseInt(rangeCol.replace(/\D/g, "")) + 1;

      const resultRowIndex = staticReadingRows
        .slice(0, rowIndex) // Take rows before the given rowIndex
        .reverse() // Reverse to start checking from the nearest row upwards
        .findIndex((row) =>
          row
            .slice(3)
            .some((item) => typeof item === "string" && item?.includes("_unit_"))
        );

      const originalRowIndex =
        resultRowIndex !== -1 ? rowIndex - 1 - resultRowIndex : -1;

      const readingValue = parseFloat(
        staticReadingRows?.[rowIndex]?.[colIndex]
      );
      const readingUnit =
        staticReadingRows?.[originalRowIndex]?.[colIndex]?.split("_unit_")?.[1];

      return (
        readingValue >= minRange.value &&
        readingValue <= maxRange.value &&
        readingUnit === minRange.unit
      );
    }

    return false;
  });

  // If filteredRanges is empty, return the entire standardRanges array
  const filteredRanges = newRanges.length > 0 ? newRanges : standardRanges;

  return (
    <div>
      <Autocomplete
        fullWidth
        id="checkboxes-tags-demo"
        size="small"
        options={
          filteredRanges != null
            ? filteredRanges?.map((item) => {
                return {
                  id: item.id,
                  stId: item.stId,
                  title: item.title,
                  rangeId: item.rangeId,
                  range: item.range?.replaceAll("#", ""),
                  accuracy: item.accuracy?.replaceAll("#", ""),
                  lc: item.lc?.replaceAll("#", ""),
                  uniformity: item.uniformity?.replaceAll("#", ""),
                  stability: item.stability?.replaceAll("#", ""),
                  mode: item.mode,
                  parameter: item.parameter,
                  paratype: item.paratype,
                  dsKey: item.dsKey,
                };
              })
            : []
        }
        value={
          valId
            ? filteredRanges
                ?.filter((stdr) => valId === "" || valId == stdr.rangeId)
                .map((stdr) => {
                  if (stdr === "") return undefined;
                  if (isSupportive) {
                    return {
                      id: stdr.id,
                      stId: stdr.stId,
                      title: stdr.title,
                      rangeId: stdr.rangeId,
                      range: stdr.range?.replaceAll("#", ""),
                      uniformity: stdr.uniformity?.replaceAll("#", ""),
                      stability: stdr.stability?.replaceAll("#", ""),
                    };
                  }
                  return {
                    id: stdr.id,
                    stId: stdr.stId,
                    title: stdr.title,
                    rangeId: stdr.rangeId,
                    range: stdr.range?.replaceAll("#", ""),
                    accuracy: stdr.accuracy?.replaceAll("#", ""),
                    lc: stdr.lc?.replaceAll("#", ""),
                    mode: stdr.mode,
                    parameter: stdr.parameter,
                    paratype: stdr.paratype,
                    dsKey: stdr.dsKey,
                  };
                })[0]
            : undefined
        }
        getOptionLabel={(option) => {
          return `${option.stId} : ${option.title}, ${`Range : ${
            option.range || ""
          }`}, ${
            !isSupportive
              ? `Accuracy: ${option.accuracy || ""}`
              : `Uniformity: ${option.uniformity || ""}`
          }, ${
            !isSupportive
              ? `LC : ${option.lc || ""}`
              : `Stability : ${option.stability || ""}`
          }, ${
            !isSupportive && option.dsKey == "0"
              ? `Mode : ${option.mode || ""}`
              : ""
          }, ${
            !isSupportive && option.dsKey == "0"
              ? `Parameter: ${option.parameter || ""}`
              : ""
          }, ${
            !isSupportive && option.dsKey == "0"
              ? `Parameter Type : ${option.paratype || ""}`
              : ""
          }`;
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.stId} <b>:</b> {option.title}, &nbsp;&nbsp;
            <span style={{ color: "	#335FFF" }}>
              Range: {option.range},&nbsp;&nbsp;
            </span>
            {isSupportive && (
              <span style={{ color: "#335FFF" }}>
                Uniformity: {option.uniformity},&nbsp;&nbsp;
              </span>
            )}
            {!isSupportive && (
              <span style={{ color: "#148F77" }}>
                Accuracy: {option.accuracy},&nbsp;&nbsp;
              </span>
            )}
            {!isSupportive && (
              <span style={{ color: "	#FF5533" }}>
                LC: {option.lc},&nbsp;&nbsp;
              </span>
            )}
            {isSupportive && (
              <span style={{ color: "	#FF5533" }}>
                Stability: {option.stability},&nbsp;&nbsp;
              </span>
            )}
            {!isSupportive && option.dsKey == "0" ? (
              <>
                <span style={{ color: "	#335FFF" }}>
                  Mode: {option.mode},&nbsp;&nbsp;
                </span>
                <span style={{ color: "	#148F77" }}>
                  Parameter: {option.parameter},&nbsp;&nbsp;
                </span>
                <span style={{ color: "	#FF5533" }}>
                  Parameter Type: {option.paratype},&nbsp;&nbsp;
                </span>
              </>
            ) : (
              ""
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder="select multiple standards"
          />
        )}
        onChange={(event, value) => {
          updateStandardRangesOfRow(
            value ? `${value?.id}:${value?.rangeId}` : "",
            isSupportive ? -1 : index + 1
          );
        }}
        // disableCloseOnSelect
      />
    </div>
  );
}
